
import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import '../../styles/statuspage/status.css'; // Import your CSS file for styling
import baseUrl from '../../config/config';
import "../../styles/table/table.css";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import "../../styles/Activity/activityLog.css";
import { DateRangePicker, defaultInputRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCircleXmark,faSortDown,faSortUp } from '@fortawesome/free-solid-svg-icons';
import api from '../../api/api';


const Activities = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem('token'); //retrive the token from the local storage
    const [details, setDetails] = useState([]);
    const [filterdDetails, setFilterdDetails] = useState([])
    const [hoveredRow, setHoveredRow] = useState(null);
    const [search, setSearch] = useState("")
    const [changed, setChanged] = useState(false);
    const [rowCount, setRowCount] = useState();
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [selectedTab, setSelectedTab] = useState('All');
    const [filteredData, setFilteredData] = useState([]);
    const itemsPerPage = 8;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const id = localStorage.getItem("user_id");
    const displayedOrders = filterdDetails?.slice(indexOfFirstItem, indexOfLastItem);
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const [startDate,setStartDate]= useState(new Date());
    const [endDate,setEndDate]= useState(new Date());
    const[visibleStartDate, setVisibleStartDate] = useState();
    const[visibleEndDate, setVisibleEndDate] = useState();
    const [datePickerToggle, setDatePickerToggle] = useState(false);
    const [showMore, setShowMore] = useState({
        activeDiv : null,
        status : false,
    });
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionsData, setOptionsData] = useState([]);
    const {assetNum} = useParams();

    const fetchDetails = async () => {
        try {
            if (!token) {
                navigate("/login")
            } else {
                const response = await api.get(`${baseUrl}/api/all-activities`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                console.log('data',response.data);
                setDetails(response.data);
                setRowCount(response.data.length);
                const assetList =[];
                response.data.map(activity => {
                    if(activity.asset.assetNumber && activity.asset.assetNumber !== null){
                        assetList.push({
                    value: activity.asset.assetNumber,
                    label: activity.asset.assetNumber,
                    });
                    }
                });
                const key = 'value';
                const optionsUniqueByKey = [...new Map(assetList.map(item =>
                    [item[key], item])).values()];

                setOptionsData(optionsUniqueByKey);
                if(assetNum && details.length > 0){
                    const parameterAssetlist = {
                        value:assetNum,
                        label:assetNum
                    }
                    handleAssetTypeChange(parameterAssetlist);
                }
            }

        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    useEffect(() => {
        if (assetNum && details.length > 0) {
          const parameterAssetlist = {
            value: assetNum,
            label: assetNum,
          };
          setTimeout(() => {

              handleAssetTypeChange(parameterAssetlist);
          },1000)
        }
    }, [details, assetNum]);
    const handleSelect = (date) => {
        setCurrentPage(1);
      
        const startDate = new Date(date.selection.startDate);
        const endDate = new Date(date.selection.endDate);
      console.log("date picker",details)
        const filtered = details.filter((activity) => {
          // Extract date part from lastUpdatedDate
          const updateDate = new Date(activity.updateDate);
          const updateDateOnly = new Date(updateDate.getFullYear(), updateDate.getMonth(), updateDate.getDate());
      
          // Extract date part from startDate and endDate
          const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
          const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
      
          // Compare date parts
          console.log("date return data",updateDateOnly >= startDateOnly && updateDateOnly <= endDateOnly)
          return updateDateOnly >= startDateOnly && updateDateOnly <= endDateOnly;
        });

        setStartDate(startDate);
        setEndDate(endDate);
        setVisibleStartDate(startDate);
        setVisibleEndDate(endDate);
        setFilterdDetails(filtered);
      };

    
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }

    const handleClearfilter = () =>{
        setStartDate(new Date());
        setEndDate(new Date());
        setVisibleStartDate("");
        setVisibleEndDate("");
        setFilterdDetails(details);
    }

    const handleAssetTypeChange = (data) => {
        let filtered = [];
        if(data !== null){
            setSelectedOption(data);

            filtered = details.filter(asset =>  asset.asset.assetNumber.toLowerCase().match(data.value.toLowerCase()));
            setFilterdDetails(filtered);
        
        }else{
            filtered = details;
            setFilterdDetails(filtered);
            setSelectedOption(null);
        }
    }

    const activeDetails = (index) =>{
        setShowMore(showMore.status = !showMore.status );
        setShowMore({...showMore, activeDiv : index});
    }
    
    const filterData = (tab) => {
        //const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        // const today = new Date();
        // const cMonth = month[today.getMonth()];
        // const year = today.getFullYear();
        // const date = details.filter(device =>month[new Date(device.lastUpdatedDate).getMonth()] == cMonth);
       // const todayDate = 
        //let filtered = [];
        // if (tab === 'All') {
        //    // filtered = details; // Show all data
        //     console.log("filtered", filtered);
        // } else if (tab === 'Primary') {
        //     //filtered = details.filter(device =>  device.assetCategory.toLowerCase().match("primary"));
        //     //console.log("filtered items",filtered)
        // } else if (tab === 'Secondary') {
        //     //filtered = details.filter(device =>  device.assetCategory.toLowerCase().match("secondary"));
        // }
        // setFilterdDetails(filtered);
    };

    useEffect(() => {
        fetchDetails();
    }, [id]);

    useEffect(() => {
        setCurrentPage(1)
    },[filterdDetails])

    const handleStatusToggle = async (objId) => {
        try {
            if (!token) {
                navigate("/login")
            } else {
                await api.post(`${baseUrl}/api/toggle-status/${objId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                fetchDetails();
            }
        } catch (error) {
            console.error('Error toggling user status:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    }

    useEffect(()=>{
        if(details){
          const result = details.filter((activity) =>{
            return activity?.user_info.email?.toLowerCase().match(search?.toLowerCase());
          });
          console.log('res value',result.length);
          setFilterdDetails(result);
          setRowCount(result.length);
        }
      },[details, search]);

    return (
        <div className='content-section col-12 col-md-12 col-xl-11 activitylog-main' >
            {/* <h2 className='white'>Device Details</h2>
      <button onClick={registerDevice}>Register Your Device</button> */}
            <div className="activitylog-head" >
                <div className="activitylog-heading">
                    <h3>Activity Log {assetNum?'- #'+assetNum:''}</h3>
                </div>
            </div>
            <div className='activitylog-filter-main'>
                <div className='row'>
                    <div className='col-12 col-md-4 col-lg-3 col-xl-3 pb-3 pb-xl-0 pb-md-0 pb-lg-0'>
                        <div className="empl-search">
                            <input type="text" placeholder='Search Email address' value={search} onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-12 col-md-4 col-lg-4 col-xl-3 pb-3 pb-xl-0 pb-md-0 pb-lg-0'>
                        <div className='activity-datepicker-button'>
                            <button onClick={() => setDatePickerToggle(!datePickerToggle)}>
                                <span className='close-datefilter'>
                                {(visibleStartDate && visibleEndDate)? <FontAwesomeIcon className="font-icons" icon={faCircleXmark} onClick={() => handleClearfilter()}/>:''}
                                </span>
                                <span className="headerSearchText">
                                    {(visibleStartDate && visibleEndDate)?`${format(visibleStartDate, "MM/dd/yyyy")} -
                                    ${format(visibleEndDate,"MM/dd/yyyy")}` : 'Start Date - End Date'}
                                </span> 
                                <FontAwesomeIcon className="font-icons" icon={faCalendarDays} />
                            </button>
                        </div>
                        {datePickerToggle && (
                            <div className='activity-datepicker-filter'>
                                <DateRangePicker
                                rangeColors={['rgba(92, 38, 128, 1)']}
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                                />
                            </div>
                        )}
                    </div>
                    <div className='col-12 col-md-4 col-lg-4 col-xl-3'>
                        <div className='assetfilter'>
                        <Select 
                        isClearable={true} 
                        id="assetType" 
                        closeMenuOnSelect={true}
                        name="assetType"
                        value={selectedOption}
                        defaultValue={selectedOption}
                        onChange={handleAssetTypeChange} 
                        options={optionsData}
                        theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#e7daef',
                            primary: '#5c2680',
                        },
                        })}/>
                        </div>
                    </div>
                </div>
            </div>
            {details.length > 0 ? (

                <div className="details-table activity-table">

                    <div className='table-section'>
                        <div className="table-container">

                        <div striped bordered hover className='activity-table'>
                            <table>

                            <thead className="activitty-thead">
                                <tr>
                                    <th className='name-hd'> User</th>
                                    <th>Date</th>
                                    <th>Event</th>
                                    <th>Asset Type</th>
                                    <th>Asset Number</th>
                                </tr>
                            </thead>
                            <Suspense fallback={<>Loading...</>}>
                                
                            <tbody>
                                {displayedOrders.map((item, index) =>

                                    <tr key={index}>
                                        <td>
                                            <div className="name-td">
                                                {/* <div className="name-td-image">{item.profile_pic == ''|| item.profile_pic == null ? <img width={50} height={50} src="/images/dummy-profile.png" alt="profile-image" className='rounded-image'/> : <img width={50} height={50} src={item.profile_pic} alt="profile-image" className='rounded-image'/>}</div> */}
                                                <div className="td-name">
                                                    <div className="td-txt">
                                                        {item.user_info.first_name+ ' '+ item.user_info.last_name}
                                                    </div>
                                                    <div className='name-td-sub-txt'>
                                                        {item.user_info.email}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        
                                        <td>
                                            <div className='td-update-date'>
                                                <div className='td-txt'>
                                                    {month[new Date(item.updateDate).getMonth()] +' '+ new Date(item.updateDate).getDate()+', '+ new Date(item.updateDate).getFullYear()}
                                                </div>
                                                <div className='name-td-sub-txt'>
                                                    {new Date(item.updateDate).toLocaleTimeString()}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='td-txt'>
                                                {item.event === 'Created'? 
                                                  
                                                    <span>
                                                        <span className='event-txt'>{item.event}</span> new asset
                                                    </span>
                                                    : item.event === 'Deleted'?
                                                    <span>
                                                        <span>
                                                            <span className='event-txt'>{item.event}</span> a asset</span>
                                                        </span>
                                                        :
                                                        // : (item.updatedFields.length === 0 || item.updatedFields.length === 1)?
                                                        <span>
                                                        { (showMore.status === false)?
                                                                <div className='event-data'>
                                                                    <span><span className='event-txt'>{item.event}</span > {(item.updatedFields.length === 0 || item.updatedFields.length === 1)? 'the field ' :  'the fields '}{item.updatedFields.map((data) =><span className='fields-txt'>{data.key}, </span>)}</span>
                                                                    <button className='show-hide-btn' onClick={() => activeDetails(index)}>Show more <FontAwesomeIcon className="font-icons" icon={faSortDown} /></button>
                                                                </div>
                                                            :(showMore.activeDiv === index)?
                                                            <>
                                                                <div className='event-data'>
                                                                    <span className='event-txt'>{item.event}</span>
                                                                    <button className='show-hide-btn' onClick={() => activeDetails(index)}>Show Less <FontAwesomeIcon className="font-icons" icon={faSortUp} /></button>
                                                                </div>
                                                                <div className={(showMore.status === true && showMore.activeDiv === index ? 'updated-fields-list active' : 'updated-fields-list')}>
                                                                    <div className='row fields-list-header'>
                                                                        <div className='col-4'> 
                                                                            <span className='fields-txt'>Field Name</span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span className='fields-txt'>Previous Value</span>
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <span className='fields-txt'>Current Value</span>
                                                                        </div> 
                                                                    </div> 
                                                                    {item.updatedFields.map((data) =>
                                                                        <>
                                                                            <div className='row fields-list-content'>
                                                                                <div className='col-4'>
                                                                                    <span className='fields-txt'>{data.key === '' ? 'null': data.key}</span>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <span className='fields-txt'>{data.prev_Value === '' ? 'null': data.prev_Value}</span>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <span className='fields-txt'>{data.current_value === '' ? 'null': data.current_value}</span>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>:
                                                            <div className='event-data'>
                                                                <span><span className='event-txt'>{item.event}</span > {(item.updatedFields.length === 0 || item.updatedFields.length === 1)? 'the field ' :  'the fields '}{item.updatedFields.map((data) =><span className='fields-txt'>{data.key}, </span>)}</span>
                                                                <button className='show-hide-btn' onClick={() => activeDetails(index)}>Show more <FontAwesomeIcon className="font-icons" icon={faSortDown} /></button>
                                                            </div>
                                                        }
                                                    </span>   
                                                }
                                            </div>
                                        </td>
                                        <td><div className='td-txt asset-type'>{item.assetType.assetType}</div></td>
                                        {/* <td style={{ color: `${item.status === "Y" ? "rgb(48, 209, 58)" : "rgb(255, 159, 10)"}` }}>
                                            {item.status === 'Y' ? 'Active' : 'Inactive'}
                                        </td> */}
                                        <td><div className='td-txt asset-number'>{item.asset.assetNumber}</div></td>
                                        
                                    </tr>
                                )}
                            </tbody>
                            </Suspense>
                            </table>
                        </div>
                        </div>


                        {filterdDetails.length > itemsPerPage &&
                  <div className="pagination-container">
                    <div className="pagination">
                      <ul className="pagination-lists">
                        <li className="page-item-prev flex-center">
                          {currentPage !== 1 ?
                            (<a
                              onClick={() => handlePageChange(currentPage - 1)}
                              className="pagination-link"
                              href="#"
                            >
                              <HiOutlineArrowSmLeft className='nxt-prev-icon' />
                            </a>) :
                            (<HiOutlineArrowSmLeft className='nxt-prev-icon' />)
                          }
                        </li>
                        {Array.from({ length: Math.min(rowCount, 5) }, (_, index) => {
                          const page = currentPage - 2 + index;
                          return page > 0 && page <= Math.ceil(rowCount / itemsPerPage) ?
                            (<li
                              key={index}
                              className={`page-item ${currentPage === page ? 'active' : ''}`}
                            >
                              <a
                                onClick={() => handlePageChange(page)}
                                className="page-link"
                                href="#"
                              >{page}</a>
                            </li>) : null;
                        })}
                        <li className="page-item-nxt flex-center">
                          {currentPage < Math.ceil(rowCount / itemsPerPage) ?
                            (<a
                              onClick={() => handlePageChange(currentPage + 1)}
                              className="pagination-link"
                              href="#"
                            ><p className='nxt-button'><HiOutlineArrowSmRight className='nxt-prev-icon' /></p>
                            </a>) :
                            (<HiOutlineArrowSmRight className='nxt-prev-icon' />)
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                }
                    </div>

                </div>
            ) : (
                <p>No records found</p>
            )}
        </div>
    );
};

export default Activities;
