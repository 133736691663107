// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: 'Poppins', sans-serif;
}
.text-center{
  text-align: center;
  color: rgb(11, 11, 92);
}
.white{
  color: white;
}
.main-content{
  display: flex;
  flex-direction:column;
}
.heading-text{
  width: 90%;
  color: #676767;
  margin-bottom: 20px;
}
.body-section {
  display: flex;
  margin-top: 78px;
}

@media screen and (max-width: 800px) {
  .main-content{
    display: flex;
    flex-direction:column;
    overflow-x: scroll;
  }
  .App{
    font-family: "Poppins";
    overflow-x: scroll;
  }
  .body-section{
    display: flex;
    overflow-x: scroll;
    min-width: 23rem;
    margin-top: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  
  .body-section{
    display: flex;
    overflow-x: scroll;
    min-width: 23rem;
    margin-top: 4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,qBAAqB;IACrB,kBAAkB;EACpB;EACA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;AACF;;AAEA;;EAEE;IACE,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');\n\n.App {\n  font-family: 'Poppins', sans-serif;\n}\n.text-center{\n  text-align: center;\n  color: rgb(11, 11, 92);\n}\n.white{\n  color: white;\n}\n.main-content{\n  display: flex;\n  flex-direction:column;\n}\n.heading-text{\n  width: 90%;\n  color: #676767;\n  margin-bottom: 20px;\n}\n.body-section {\n  display: flex;\n  margin-top: 78px;\n}\n\n@media screen and (max-width: 800px) {\n  .main-content{\n    display: flex;\n    flex-direction:column;\n    overflow-x: scroll;\n  }\n  .App{\n    font-family: \"Poppins\";\n    overflow-x: scroll;\n  }\n  .body-section{\n    display: flex;\n    overflow-x: scroll;\n    min-width: 23rem;\n    margin-top: 4rem;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  \n  .body-section{\n    display: flex;\n    overflow-x: scroll;\n    min-width: 23rem;\n    margin-top: 4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
