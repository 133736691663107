// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-main {
    background: #fcfbfc;
}

.assethead-head {
    width: 100%;
    display: flex;
    padding: 20px 0px 30px 0px;
    border-bottom: 2px solid #ebebeb;
}
`, "",{"version":3,"sources":["webpack://./src/styles/assetModify/assetModify.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,0BAA0B;IAC1B,gCAAgC;AACpC","sourcesContent":[".asset-main {\n    background: #fcfbfc;\n}\n\n.assethead-head {\n    width: 100%;\n    display: flex;\n    padding: 20px 0px 30px 0px;\n    border-bottom: 2px solid #ebebeb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
