import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Make sure to use 'react-router-dom'
import baseUrl, { corporateUrl } from '../../config/config';
import '../../styles/deviceDetails/deviceDetails.css';
import "../../styles/allAssets/allAssets.css"
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { BsInfoCircle,BsX } from "react-icons/bs";
import icons from '../../data/assetTypes';
import Popupq from '../../components/popupq/PopupQ';
import api from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdSpeaker } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';


const AssetDetails = () => {
    const navigate = useNavigate(); 
    const token = localStorage.getItem('token');
    const { objId, empId } = useParams();
    const [userDetails, setDetails] = useState(null);
    //const [employeeDetails, setEmployeeDetails] = useState(null);
    const [assetDatas, setAssetDatas ] = useState(null);
    const [secondaryDetails, setSecondaryDetails] = useState([]);
    const [assetDetailIcon,setAssetDetailIcon] = useState(null);
    const [assetIcons,setAssetIcons] = useState(null);
    const isMounted = useRef(false);
    const [showPopup,setShowPopup] = useState(false);
    const isAdmin =localStorage.getItem("access_type");
    const event = 'Deleted';
    const id = localStorage.getItem("user_id");

    const [activityData, setActivityData] = useState({
        assetNumber: '',
        employeeId: id,
        event: event,
    });
    const handleViewFunction = (objectID, assetNumber) =>{
        navigate(`/asset-details/${objectID}/${encodeURIComponent(assetNumber)}`)
        fetchAssetData(objectID)
    }

    useEffect(() => {
        isMounted.current = true;
        fetchAssetData(objId);
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getbscolor = (status) => {
        switch (status) {
          case 'New/Verified':
            return '#ebf2ff';
          case 'In stock':
            return '#f6f0ff';
          case 'Assigned':
            return '#ecfdf3';
          case 'Maintenance':
            return '#fbd5bf';
          case 'Returned':
            return '#fcf3d7';
          case 'Clearance Done':
            return '#ffe9f2';
          case 'To Be Decommissioned':
            return '#fee8e7';
          case 'Lost/Stolen':
              return '#ff9ca5';
          case 'Obsolete':
            return '#ededf0';
          default:
            return {};
        }
    };

    const getcolor= (status) => {
        switch (status) {
          case 'New/Verified':
            return '#76a9ff'
          case 'In stock':
            return '#5c2680'
          case 'Assigned':
            return '#008000'
          case 'Maintenance':
            return '#e4521d'
          case 'Returned':
            return '#f3ae3f'
          case 'Clearance Done':
            return '#ff6eaa'
          case 'To Be Decommissioned':
            return '#c8372d'
          case 'Lost/Stolen':
            return '#811a00'
          case 'Obsolete':
            return '#4a4b57'
          default:
            return {};
        }
      };

    const findassetDetailIcon = (assetType) =>{
        const matchingIcon = icons.find(icon => icon['type'] === assetType);
        setAssetDetailIcon(matchingIcon["icon"]);
    }
    const fetchAssetData = async (objId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.log("No token available when calling device data");
                navigate("/login");
            } else {
                const [assetDetailResponse, allAssetsResponse] = await Promise.all([
                    api.get(`${baseUrl}/api/asset-detail/${objId}`, {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                        }
                    }),
                    api.get(`${baseUrl}/api/all-assets-details`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    })
                ]);

                const detailData = assetDetailResponse.data[0];
                if (isMounted.current) {
                    setAssetDatas (detailData);
                    findassetDetailIcon(detailData?.assetType?.assetType);
                    const filtered = allAssetsResponse.data.filter(asset => 
                        asset.assetType?.assetCategory.toLowerCase().includes('secondary') &&
                        asset.assetDetails.parentAsset === detailData.assetDetails.assetNumber
                    );
                    setSecondaryDetails(filtered);

                }
                const allAssetsData = allAssetsResponse.data;
                const iconsMap = {};
                allAssetsData.forEach(asset => {
                    const matchingIcon = icons.find(icon => icon['type'] === asset.assetType?.assetType);
                    if (matchingIcon) {
                    iconsMap[asset.assetType?.assetType] = matchingIcon["icon"];
                    } else {
                    console.warn(`No matching icon found for assetType: ${asset.assetType?.assetType}?.assetType`);
                    }
                });
                setAssetIcons(iconsMap);

            }
        } catch (err) {
            console.error("Error fetching device data:", err);
        }
    };

    const handleUpdate = (objId, assetNumber) => {
        console.log("Asset id",objId)
        navigate(`/update/${encodeURIComponent(assetNumber)}/${objId}`)
    };

    const handleRemoveFunction = async (asset) =>{
        const objId = asset.assetDetails._id; // Extract objId from the current asset object
        asset.assetDetails.status = 'In Stock';
        asset.assetDetails.allocatedPersonId = '';
        asset.assetDetails.parentAsset = '';
        asset.assetDetails.fields=[];
        console.log('remove',asset.assetDetails);
        const response = await api.put(`${baseUrl}/api/asset-update/${objId}`, asset.assetDetails, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        window.location.reload();
    }

    const renderFieldDetails = (fieldDetails = [], specifications = []) => {
        return fieldDetails.map((field) => {
            const spec = specifications.find((spec) => spec.feildId === field.feildId);
            let option;
            
            if (field.fieldType === "dropdown" && spec) {
              const valueAsNumber = Number(spec.fieldValue);
              option = field.options ? field.options.find((option) => option.value === valueAsNumber) : '';
            }
          return (
            <>
                <div className='asset_details_row_section' key={field.fieldId}>
                    <div className='asset_details_label'>{field.fieldName}:</div>
                    <div className='asset_details_desc'>{field.fieldType === "dropdown" ? option?.label:spec ? spec.fieldValue : 'N/A'}</div>
                </div>
                {isAdmin === '1' && field.feildName?.toLowerCase() === 'windows license' && (
                    <div className='asset_details_row_section' key={field.fieldId}>
                        <div className='asset_details_label'>{field.fieldName}:</div>
                        <div className='asset_details_desc'>{field.fieldType === "dropdown" ? option?.label:spec ? spec.fieldValue : 'N/A'}</div>
                    </div>
                )}
            </>
            );
            
        });
    };

    const handleDelete = async (item) => {
        // Show a confirmation dialog for deleting the main asset
        const userConfirmed = window.confirm('Are you sure you want to delete this device entry?');
        if (userConfirmed) {
            try {
                if (!token) {
                    navigate("/login");
                } else {
                    // Delete the main asset
                    await api.delete(`${baseUrl}/api/asset-delete/${item.assetDetails._id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                        },
                    });
                    // Prepare activity details
                    const activityDetails = {};
                    if (item.assetDetails.assetNumber) {
                        activityDetails.assetNumber = item.assetDetails.assetNumber;
                        activityDetails.employeeId = activityData.employeeId;
                        activityDetails.event = activityData.event;
                    }
    
                    // Add activity
                    await api.post(`${baseUrl}/api/add-activity`, activityDetails, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                        },
                    });
    
                    // if (item.assetCategory === 'primary' && secondaryDetails) {
                        if (item.assetType.assetCategory === 'primary') {
                        // Ask user if they want to delete all subassets
                        const deleteSubAssetsConfirmed = await new Promise((resolve) => {
                            const resolveFunction = (value) => {
                                resolve(value);
                            };
                            setShowPopup({ visible: true, resolveFunction });
                        });


                        if (deleteSubAssetsConfirmed) {
                            // Delete all subassets
                            for (const asset of secondaryDetails) {
                                if (asset.assetDetails.parentAsset === item.assetDetails.assetNumber) {
                                    await api.delete(`${baseUrl}/api/asset-delete/${asset.assetDetails._id}`, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                                        },
                                    });
                                }
                            }
                        } else {
                            // Update subassets to remove the parentAsset reference
                            for (const asset of secondaryDetails) {
                                if (asset.assetDetails.parentAsset === item.assetDetails.assetNumber) {
                                    let newAsset = { ...asset, parentAsset: '' };
                                    await api.post(`${baseUrl}/api/device-update/${asset.assetDetails._id}`, newAsset, {
                                        headers: {
                                            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                                        },
                                    });
                                }
                            }
                        }
                    }
    
                    // Navigate to all assets and fetch details
                    navigate(`/all-assets`);
                }
            } catch (error) {
                console.error('Error deleting entry:', error);
                // Optionally, set error status here
                // setDeleteStatus('Error deleting entry');
            }
        } else {
            alert("Error deleting asset");
        }
    };

    return (
        <div className='content-section col-12 col-md-12 col-xl-11 asset-section asset-dashboard'>    
            <div className='w-100'>   
                <div data-tooltip-id="tool-tip-back-button" data-tooltip-content="Back" className='backBtn' onClick={() => navigate(-1)}><HiOutlineArrowSmLeft className='nxt-prev-icon' /></div>
                <Tooltip id="tool-tip-back-button" className="asset-tooltip" border="1px solid #5c2680" place = "right"/>
            </div> 

            <div className='container'>
                <div className='asset_detail_main'>
                    <div className='asset_detail_content_section'>
                        <div className='asset_detail_content_container'>
                            <div className='asset_detail_content_container_header'>
                                <div className='device_details_heading'>
                                    <div className='asset-detail-image asset-image'>
                                        {assetDatas?.assetType?.assetType === "Speaker"? <MdSpeaker />:<FontAwesomeIcon className="font-icons" icon={assetDetailIcon}/>}
                                    </div>
                                    <div className='asset-detail-name-title'>
                                        {assetDatas?.assetDetails?.assetNumber || "Loading..."}
                                        <span className='asset-details-subtitle'>{assetDatas?.assetType?.assetType}</span>
                                    </div>
                                </div>
                                {isAdmin && isAdmin === "1" && (
                                    <>
                                        <div className='device_details_edit' onClick={() => handleUpdate(assetDatas.assetDetails?._id, assetDatas?.assetDetails.assetNumber)}>
                                            <p className='device_details_edit_text'>Edit</p>
                                            <FiEdit3 className="action-icon"/>
                                        </div>
                                        <div className='device_details_delete' onClick={() => handleDelete(assetDatas)}>
                                            <p className='device_details_delete_text'>Delete</p>
                                            <BsTrash className="action-icon"/>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className='asset_details_row_group'>
                                <div className='asset_details_row_group_heading'>Asset Details</div>

                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Asset Number:</div>
                                    <div className='asset_details_desc'>{assetDatas?.assetDetails?.assetNumber}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Asset Type:</div>
                                    <div className='asset_details_desc'>{assetDatas?.assetType?.assetType}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Category:</div>
                                    <div className='asset_details_desc'>{assetDatas?.assetType?.assetCategory}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Purchased Year:</div>
                                    <div className='asset_details_desc'>{assetDatas?.assetDetails?.yearOfPurchase}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className={`asset_details_label`}>Status:</div>
                                    <div className='asset-status pb-4'>
                                        <span key={assetDatas?.assetDetails.status} className={assetDatas?.assetDetails.status == 'Assigned' ? 'active' : ''} style={{ backgroundColor : getbscolor(assetDatas?.assetDetails.status), color : getcolor(assetDatas?.assetDetails.status),}}>{assetDatas?.assetDetails.status}</span>
                                    </div>
                                </div>
                            </div>

                            <div className='asset_details_row_group'>
                                <div className='asset_details_row_group_heading'>Allocated Details</div>

                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Employee Id:</div>
                                    <div className='asset_details_desc'>{assetDatas?.allocatedPersonDetails?.employeeId}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Allocated Person:</div>
                                    <div className='asset_details_desc'>{assetDatas?.allocatedPersonDetails?assetDatas?.allocatedPersonDetails?.first_name+' '+ assetDatas?.allocatedPersonDetails?.last_name:''}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Role:</div>
                                    <div className='asset_details_desc'>{assetDatas?.roleDetails?.roleName}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Seat Allocation :</div>
                                    <div className='asset_details_desc'>{assetDatas?.allocatedPersonDetails?.seat_allocation}</div>
                                </div>
                            </div>

                            <div className='asset_details_row_group'>
                                <div className='asset_details_row_group_heading'>Asset Specifications</div>
                                {renderFieldDetails(assetDatas?.fieldDetails, assetDatas?.specifications)}
                            </div>

                            <div className='asset_details_row_group'>
                                <div className='asset_details_row_group_heading'>Purchase Details</div>

                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Create Date:</div>
                                    <div className='asset_details_desc'>{new Date(assetDatas?.assetDetails?.createDate).toDateString()}</div>
                                </div>
                                <div className='asset_details_row_section'>
                                    <div className='asset_details_label'>Last Updated Date:</div>
                                    <div className='asset_details_desc'>{new Date(assetDatas?.assetDetails?.updateDate).toDateString()}</div>
                                </div>
                                {isAdmin && isAdmin === '1' ?
                                    (
                                        <div className='asset_details_row_section'>
                                            <div className='asset_details_label'>Remarks:</div>
                                            <div className='asset_details_desc'>{assetDatas?.assetDetails?.remarks}</div>
                                        </div>
                                    ) : ""
                                }
                            </div>

                            { assetDatas?.assetType?.assetCategory.toLowerCase() === 'primary' && secondaryDetails.length > 0 && (
                            <div className='asset_details_row_group secondary-assets-row-group'>
                                <div className='asset_details_row_group_heading'>Secondary Assets</div>
                                    <div className='asset_details_row_section_slider'>
                                    {secondaryDetails.length > 0 ? (
                                        secondaryDetails.map((element, index) => (
                                            // <div className='asset-item-main col-12 col-md-12 col-lg-12 col-xl-4 '> 
                                            <div className='asset-item-main'>
                                                <div className='asset-item h-100'>
                                                    {/* <div className='asset-item-image col-2'>
                                                        <img></img>
                                                    </div> */}
                                                    <div className='asset-content-inner  col-12'>
                                                    
                                                    <div className='asset-innersec'>
                                                        <div className='asset-item-image-title'>
                                                            <div className='asset-item-image-inner'>
                                                                {element?.assetType?.assetType === "Speaker"? <MdSpeaker />:<FontAwesomeIcon className="font-icons" icon={assetIcons[element.assetType.assetType]}/>}
                                                            </div>
                                                            <div className='asset-item-number'>
                                                                {element.assetDetails.assetNumber}
                                                            </div>
                                                        </div>
                                                        <div className='asset-item-action'>
                                                            {/* {hoveredRow === index ? ( */}
                                                                <div className='action-icons'>
                                                                <BsInfoCircle className="action-icon" onClick={() => handleViewFunction(element.assetDetails._id, element.assetDetails.assetNumber)}/>
                                                                {isAdmin && isAdmin === '1' &&
                                                                  (
                                                                    <>
                                                                        <FiEdit3 className="action-icon" onClick={() => handleUpdate(element.assetDetails._id, element.assetDetails.assetNumber)}/>
                                                                        <Tooltip id="tool-tip-remove-sub-asset" className="asset-tooltip" border="1px solid #5c2680" />
                                                                        <BsX className="action-icon" data-tooltip-id="tool-tip-remove-sub-asset" data-tooltip-content="Remove secondary asset" onClick={() => handleRemoveFunction(element)}/>
                                                                    </>
                                                                  )}
                                                                    
                                                                </div>
                                                            {/* ) : (
                                                            <BsThreeDotsVertical className="three"  onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave} />
                                                            )} */}
                                                        </div>
                                                    </div>   
                                                    

                                                    <div className='asset-item-type'>
                                                        <span className='asset-label'>Asset Type: </span>
                                                        <span className='asset-data'>{element.assetType.assetType}</span>
                                                    </div>

                                                    <div className='asset-item-category'>
                                                        <span className='asset-label'>Category: </span>
                                                        <span className='asset-data'>{element.assetType.assetCategory}</span>
                                                    </div>
                                                    
                                                    <div className={element.allocatedPersonDetails ?'asset-item-allocated d-flex flex-column flex-md-row justify-content-between':'asset-item-allocated d-flex justify-content-end'}>
                                                        {element.allocatedPersonDetails ?
                                                        <div className='asset-allocated-person'>
                                                            <span className='asset-label'>User: </span>
                                                            <span className='asset-data'>{element.allocatedPersonDetails.first_name+' '+ assetDatas.allocatedPersonDetails.last_name}</span>
                                                        </div>
                                                        : ''}
                                                        <div className='asset-status'>
                                                            <span className={element.assetDetails.status == 'Assigned' ? 'active' : ''}>{element.assetDetails.status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> </div>
                                    
                                    ))
                                    ) : (
                                        <div>No secondary assets found.</div>
                                    )}
                                    {showPopup.visible && (
                                        <Popupq
                                            message="Are you sure you want to delete these sub-assets?"
                                            data1="Yes"
                                            data2="No"
                                            setShowPopup={() => setShowPopup({ visible: false })}
                                            resolveFunction={showPopup.resolveFunction}
                                        />
                                    )}
                                    </div>
                                </div>  

                                    )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetDetails;
