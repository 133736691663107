
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../styles/statuspage/status.css'; // Import your CSS file for styling
import baseUrl, { corporateUrl } from '../../config/config';
import { BsInfoCircle } from "react-icons/bs";
import { Table } from 'react-bootstrap';
import "../../styles/table/table.css";
import "../../styles/allAssets/allAssets.css";
import "../../styles/assignedDashboard/assignedDashboard.css"
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComputer,faUserCheck,faCheckCircle,faUsers,faAngleRight} from '@fortawesome/free-solid-svg-icons';
import api from '../../api/api';

import icons from '../../data/assetTypes';
import { MdSpeaker } from "react-icons/md";

const AssignedAssets = ({setActiveIndex}) => {
  const { id } = useParams();
  const navigate = useNavigate()
  const token = localStorage.getItem('token'); //retrive the token from the local storage
  const [details, setDetails] = useState([]);
  const [filterdDetails, setFilterdDetails] = useState([])
  const [rowCount, setRowCount] = useState();
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage ? parseInt(savedPage, 10) : 1; // Default to page 1 if not found
  });  // Current page number
  const itemsPerPage = 9;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedOrders = details?.slice(indexOfFirstItem, indexOfLastItem);
  const userId = localStorage.getItem("user_id");
  const [userDetails, setUserDetails] = useState([]);
  const [assetsCount, setAssetsCount] = useState();
  const [employessCount, setEmployessCount] = useState();
  const [assignedAssetsCount, setAssignedAssetsCount] = useState();
  const [instockAssetsCount, setInstockAssetsCount] = useState();
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [assignedPersonList, setAssignedPersonList] = useState([]);
  const [assetIcons,setAssetIcons] = useState(null);
  const [load, setLoad] = useState(true);

  var today = new Date()
  var currentTime = today.getHours()
  const fetchDetails = async () => {
    try {
      if(!token){
        navigate("/login")
      }else{
        const response = await api.get(`${baseUrl}/api/assigned-assets/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        const assignedData = response.data;
        setDetails(response.data);
        setFilterdDetails(response.data)
        setRowCount(response.data.length)

        const iconsMap = {};
        console.log('assigned',assignedData);
        assignedData.forEach(asset => {
            const matchingIcon = icons.find(icon => icon['type'] === asset.assetType?.assetType);
            if (matchingIcon) {
            iconsMap[asset.assetType?.assetType] = matchingIcon["icon"];
            } else {
            console.warn(`No matching icon found for assetType: ${asset.assetType?.assetType}`);
            }
        });
        console.log('iconsmap',iconsMap);
        setAssetIcons(iconsMap);

        const userResponse = await api.get(`${corporateUrl}/api/user-details/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setUserDetails(userResponse.data);

        const assetsResponse = await api.get(`${baseUrl}/api/all-assets-details`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setLoad(false);
        setAssetsCount(assetsResponse.data.totalCount);
        setAssignedAssetsCount(assetsResponse.data.totalAssignedCount)
        setInstockAssetsCount(assetsResponse.data.totalUnassignedCount)
        setEmployessCount(assetsResponse.data.totalUserCount);
        const countList =[];
        
        assetsResponse.data.results.forEach(asset => {
          const existingAsset = countList.find(item => item.assetType === asset.assetType?.assetType);
          
          if (existingAsset) {
              existingAsset.count += 1;
          } else {
              countList.push({
                  assetType: asset.assetType?.assetType,
                  assetCategory: asset.assetType?.assetCategory,
                  count: 1
              });
          }
        });
        const key = 'assetType';
        const arrayUniqueByKey = [...new Map(countList.map(item =>
            [item[key], item])).values()];

        const sortedAssetTypeList = arrayUniqueByKey.sort((a, b) => b.count - a.count);
        setAssetTypeList(sortedAssetTypeList);

        const employeesResponse = await api.get(`${corporateUrl}/api/all-users-asset`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        const countMap = {};
        employeesResponse.data.forEach(item => {
          const key = item.employeeId;
          if (!countMap[key]) {
            countMap[key] = {
              emp_id: item.employeeId,
              emp_image: item.profile_pic,
              emp_name: item.first_name + ' ' + item.last_name,
              emp_mail: item.email,
              emp_role: item.role
              // count: 0 // Initialize count
            };
          }
        });
        const assignedList = [];
        await assetsResponse.data.results.forEach(person => {
          const matchingPerson = countMap[person.allocatedPersonDetails?.employeeId];
          if (matchingPerson && person.assetDetails.status == 'Assigned') {
            if (assignedList.length === 0) {
              assignedList.push({
                emp_id: matchingPerson.emp_id,
                emp_image: matchingPerson.emp_image,
                emp_name: matchingPerson.emp_name,
                emp_mail: matchingPerson.emp_mail,
                emp_role: matchingPerson.emp_role,
                count: 1,
              });
            } else {
              const existingEntry = assignedList.find(emp => emp.emp_id === matchingPerson.emp_id);
              
              if (!existingEntry) {
                // If the person is not already in assignedList, add them
                assignedList.push({
                  emp_id: matchingPerson.emp_id,
                  emp_image: matchingPerson.emp_image,
                  emp_name: matchingPerson.emp_name,
                  emp_mail: matchingPerson.emp_mail,
                  emp_role: matchingPerson.emp_role,
                  count: 1,
                });
              } else{
                // If the person is already in assignedList, increment their count
                existingEntry.count++;
              }
            }
          }
        });
        const sortedAssignedList = assignedList.sort((a, b) => b.count - a.count);
        setAssignedPersonList(sortedAssignedList);
      }
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  useEffect(() => {  
    localStorage.setItem("user_id", id)
    fetchDetails();
  }, [id]);

  const handleStatusToggle = async (objId) => {
    try {
      if(!token){
        navigate("/login")
      }else{
        await api.post(`${baseUrl}/api/toggle-status/${objId}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        fetchDetails();
      }  
    } catch (error) {
      console.error('Error toggling user status:', error);
    }
  };

  const BackToHome = () =>{
    navigate("/")
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    localStorage.setItem('currentPage', newPage);
  }

  const handleViewFunction = (objectID, assetNumber) =>{
    navigate(`/asset-details/${objectID}/${encodeURIComponent(assetNumber)}`)
  }
  
  return (
    <div className='content-section col-12 col-md-12 col-xl-11 asset-section asset-dashboard'>
      {load ?
        <div className='d-flex align-items-center justify-content-center h-100 w-100'>
          <div className='d-flex flex-column align-items-center'>
            <div className='loading-image'><img  src="/images/fade-stagger-circles.svg" alt="Profile Pic" className='loading-image-item'/></div>
            <div className='loading-text'>Loading</div>
          </div>
        </div>
      :
        <div className='asset-all'>
          <div className='hello-head'>
            <div className='hello-text'>
              <span>Hello, {userDetails.first_name+ ' '+ userDetails.last_name} !</span>
            </div>
            <div className='wish-text'>
              {currentTime<12 ? <h5>Good Morning</h5>: currentTime< 18 ? <h5>Good Afternoon</h5>: <h5>Good Evening</h5>}
            </div>
          </div>
          {userDetails && (userDetails.access_type === 1 || userDetails.access_type === 3) && ( 
            <>
              <div className='dashboard-count-section'>
                <div className='count_main row'>
                  <div className='count_box col-3'>
                    <div>
                      <span className='count_box_icon'> <FontAwesomeIcon className="font-icons" icon={faComputer}/></span>
                    </div>
                    
                    <div className='count_content'>
                      <span className='count_box_title'> Number of Assets</span>
                      <span className='count_box_item_count'>{assetsCount}</span>
                    </div>
                  </div>

                  <div className='count_box col-3'>
                    <div>
                      <span className='count_box_icon'> <FontAwesomeIcon className="font-icons" icon={faUsers}/></span>
                    </div>
                    
                    <div className='count_content'>
                      <span className='count_box_title'> Total Employees</span>
                      <span className='count_box_item_count'>{employessCount}</span>
                    </div>
                  </div>

                  <div className='count_box col-3'>
                    <div>
                      <span className='count_box_icon'> <FontAwesomeIcon className="font-icons" icon={faUserCheck}/></span>
                    </div>
                    
                    <div className='count_content'>
                      <span className='count_box_title'>Assigned Assets</span>
                      <span className='count_box_item_count'>{assignedAssetsCount}</span>
                    </div>
                  </div>

                  <div className='count_box col-3'>
                    <div>
                      <span className='count_box_icon'> <FontAwesomeIcon className="font-icons" icon={faCheckCircle}/></span>
                    </div>
                    
                    <div className='count_content'>
                      <span className='count_box_title'> Unassigned Assets</span>
                      <span className='count_box_item_count'>{instockAssetsCount}</span>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className='dashboard-count-lists d-flex flex-column flex-md-row'>
                <div className='asset-dashboard-details-section aaset-type-count-list-section w-100 w-md-50 w-lg-50 w-xl-100'>
                  <div className='asset-dashboard-sub-head'>
                      <h6>Asset Type</h6>
                  </div>
                  <div>
                    <div className='asset-dashboard-item-head-section w-100 w-md-50 w-lg-50 w-xl-100'>
                      <div className='item-heading'>
                        <span>Asset Type Name</span>
                      </div>
                      <div className='item-heading'>
                        <span>Count</span>
                      </div>
                    </div>
                    <div className='item-content-section'>
                      {assetTypeList.length > 0 ? (
                          <div className='asset-list-section'>
                          {assetTypeList.map((item, index) =>
                          <div className='asset-list-item-main'>
                            <button className='asset-list-item'>
                              <div className='aset-list-content'>
                                <div className='asset-type-img'>

                                </div>
                                <div className='asset-type'>
                                    <span>{item.assetType}</span>
                                </div>
                              </div>
                                
                              <div className='asset-count'>
                                  <span>{item.count}</span>
                              </div>
                            </button>
                          </div>
                          )}
                          </div>
                      ) : (
                        <div className='d-flex flex-column align-items-center'>
                          <div className='loading-image'><img  src="/images/fade-stagger-circles.svg" alt="Profile Pic" className='loading-image-item'/></div>
                          <div className='loading-text'>Loading</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='asset-dashboard-details-section asset-emp-count-list-section w-100 w-md-50 w-lg-50 w-xl-100'>
                  <div className='asset-dashboard-sub-head'>
                      <h6>Assigned</h6>
                  </div>
                  <div className='asset-dashboard-item-head-section'>
                    <div className='item-heading'>
                      <span>Assigned Person Details</span>
                    </div>
                    <div className='item-heading'>
                      <span>Count</span>
                    </div>
                  </div>
                  <div className='item-content-section'>
                      {assignedPersonList.length > 0 ? (
                          <div className='asset-list-section'>
                          {assignedPersonList.map((item, index) =>
                            <div className='asset-personList-inner'> 
                              <div className=' asset-personList-main'> 
                                <div className='d-flex gap-2'>
                                  <div className='asset-person-details'>
                                    <div className='asset-person-image'>
                                    {item.emp_image == ''|| item.emp_image == null ? <img width={50} height={50} src="/images/profile-user.png" alt="Profile Pic" className="profile-pic" /> : <img width={50} height={50} src={item.emp_image} alt="Profile Pic" className="profile-pic" />}
                                    </div>
                                    <div className='asset-person'>
                                      <div className='asset-person-name'>
                                        <span>{item.emp_name}</span>
                                      </div>
                                      <div className='asset-person-role'>
                                        <span>#{item.emp_id}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='person-asset-mail'>
                                    <span>{item.emp_mail}</span>
                                  </div>
                                </div>
                                
                                <div className='person-asset-count'>
                                  <span>{item.count}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          </div>
                      ) : (
                        <div className='d-flex flex-column align-items-center'>
                          <div className='loading-image'><img  src="/images/fade-stagger-circles.svg" alt="Profile Pic" className='loading-image-item'/></div>
                          <div className='loading-text'>Loading</div>
                        </div>
                      )}
                    </div>
                </div>
              </div>
            </>
          )}
          <div className="asset-head" >
              <div className="main-heading">
                  <h3>My Assets Inbox</h3>
              </div>
          </div>
          {details.length > 0  ? (
            <div className='row asset-items py-5'>
                {displayedOrders.map((item, index) =>
                    <div className='asset-item-main col-12 col-md-6 col-lg-6 col-xl-4'>
                        <div className='asset-item h-100'>
                            <div className='asset-content-inner  col-12'>
                                
                                <div className='asset-innersec'>
                                  <div className='asset-item-image-title'>
                                        <div className='asset-item-image-inner'>
                                              {item?.assetType?.assetType === "Speaker"? <MdSpeaker />:<FontAwesomeIcon className="font-icons" icon={assetIcons[item.assetType.assetType]}/>}
                                        </div>
                                        <div className='asset-item-number'>
                                              {item.assetDetails?.assetNumber}
                                        </div>
                                      </div>
                                    <div className='asset-item-action'>
                                      <div className='action-icons'>
                                        <BsInfoCircle className="action-icon" onClick={() => handleViewFunction(item.assetDetails._id, item.assetDetails.assetNumber)}/>
                                      </div>
                                    </div>
                                </div>   
                                

                                <div className='asset-item-type'>
                                    <span className='asset-label'>Asset Type: </span>
                                    <span className='asset-data'>{item.assetType.assetType}</span>
                                </div>

                                <div className='asset-item-category'>
                                    <span className='asset-label'>Category: </span>
                                    <span className='asset-data'>{item.assetType.assetCategory}</span>
                                </div>
                                
                                <div className={item.allocatedPersonDetails ?'asset-item-allocated d-flex flex-column flex-md-column flex-xl-row justify-content-between':'asset-item-allocated d-flex flex-column flex-md-column flex-xl-row justify-content-end'}>
                                    {item.allocatedPersonDetails ?
                                    <div className='asset-allocated-person'>
                                        <span className='asset-label'>User: </span>
                                        <span className='asset-data'>{item.allocatedPersonDetails.first_name+' '+item.allocatedPersonDetails.last_name}</span>
                                    </div>
                                    : ''}
                                    <div className='asset-status'>
                                        <span className={item.assetDetails.status == 'Assigned' ? 'active' : ''}>{item.assetDetails.status}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                { filterdDetails.length > itemsPerPage &&
                    <div className="pagination-container">
                        <div className="pagination">
                            <ul className="pagination-lists">
                                <li className="page-item-prev flex-center">
                                    {currentPage !== 1 ?
                                    (<a
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        className="pagination-link"
                                        href="#"
                                    >
                                        <HiOutlineArrowSmLeft className='nxt-prev-icon' />
                                    </a>) :
                                    (<HiOutlineArrowSmLeft className='nxt-prev-icon' />)
                                    }
                                </li>
                                {Array.from({ length: Math.min(rowCount, 5) }, (_, index) => {
                                    const page = currentPage - 2 + index;
                                    return page > 0 && page <= Math.ceil(rowCount / itemsPerPage) ?
                                    (<li
                                        key={index}
                                        className={`page-item ${currentPage === page ? 'active' : ''}`}
                                    >
                                        <a
                                        onClick={() => handlePageChange(page)}
                                        className="page-link"
                                        href="#"
                                        >{page}</a>
                                    </li>) : null;
                                })}
                                <li className="page-item-nxt flex-center">
                                    {currentPage < Math.ceil(rowCount / itemsPerPage) ?
                                    (<a
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        className="pagination-link"
                                        href="#"
                                    ><p className='nxt-button'><HiOutlineArrowSmRight className='nxt-prev-icon' /></p>
                                    </a>) :
                                    (<HiOutlineArrowSmRight className='nxt-prev-icon' />)
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>  
        ) : (
          <div className='no-data-section'>
            <div className='no-data-image-section'>
              <img  src="/images/not-found.png" alt="Profile Pic" className='no-data-image'/>
            </div>

            <div className='no-data-text-section'>
              <p>No Records Found</p>
              <span>No assets are assigned to you now. Please check later for any new assignments.</span>
            </div>
          </div>
        )}
       
      </div>
    }
    </div>  
  );
};

export default AssignedAssets;
