import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import CreateAsset from "./pages/CreateAssetPage/CreateAssets";
import AssignedAssets from "./pages/AssignedAssetsPage/AssignedAssets";
import Login from "./pages/loginPage/Login";
import Update from "./pages/updatePage/Update";
import { useEffect, useState } from "react";
import ProtectedRoute from "./routes/ProtectedRoutes";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Nabvar";
import AllAssets from "./pages/AllAssetsPage/AllAssets";
import Home from "./pages/HomePage/Home";
import AssetDetails from "./pages/AssetDetailsPage/AssetDetails";
import Activities from "./pages/ActivityLogPage/Activities";
function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );

  useEffect(() => {
    // Update localStorage when authentication state changes
    localStorage.setItem("authenticated", authenticated);
  }, [authenticated]);
  const [activeIndex,setActiveIndex] = useState(1);
  return (
    <div className="App">
      <div className="main-content">
        <Navbar />
        <div className="body-section">
          <div className="d-flex flex-column flex-xl-row w-100">
            {authenticated && 
            <div className="sidebar-section min-vh-0">
              <Sidebar  activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            </div>}
            
            <Routes>
              {/* <Route element={<ProtectedRoute isAuthenticated={authenticated} />} > */}

              <Route path="/create-asset/:id" element={<CreateAsset setActiveIndex={setActiveIndex}/>} />
              <Route path="/assigned-assets/:id" element={<AssignedAssets setActiveIndex={setActiveIndex}/>} />
              <Route path="/all-assets" element={<AllAssets setActiveIndex={setActiveIndex}/>} />
              <Route path="/update/:id/:objId" element={<Update  setActiveIndex={setActiveIndex}/>} />
              <Route path="/asset-details/:objId/:empId" element={<AssetDetails/>} />
              <Route path="/activities" element={<Activities />} />
              <Route path="/activities/:assetNum" element={<Activities />} />

              {/* </Route> */}
              <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
              />
              <Route path="/" element={<Home/>} />
            </Routes>
            {/* <RegisterForm /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
