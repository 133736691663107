// Popup.js
import React from 'react';
import '../../styles/popup/popup.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Popup = ({ message, type, onClose, action }) => {
  return (
    <div className={`popup ${type}`} >
      <div className='row'>
        <div className='col-11'>
          <p>{message}</p>
        </div>
        <div className='col-1'>
          <span onClick={onClose}><FontAwesomeIcon className='font-icons' icon={faClose} />{/*`${/*action && action === 'change_password' ? 'Change Password' : 'Close'}`*/}</span>
        </div>
      </div>
    </div>
  );
};

export default Popup;

