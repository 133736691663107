const status = [
    {
      value: 1,
      label:'New/Verified'
    },
    {
      value: 2,
      label:'In stock'
    },
    {
      value: 3,
      label:'Assigned'
    },
    {
      value: 4,
      label:'Maintenance'
    },
    {
      value: 5,
      label:'Returned'
    },
    {
      value: 6,
      label:'Clearance Done'
    },
    {
      value: 7,
      label:'To Be Decommissioned'
    },
    {
      value: 8,
      label:'Lost/Stolen'
    },
    {
      value: 9,
      label:'Obsolete'
    }
  ]

  export default status;