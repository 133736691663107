// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.details-table { 
    margin-top: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    padding: 10px;
  }
  
  thead {
    background-color: #f2f2f2;
  }
  
  th {
    font-weight: bold;
  }
  .btn-left{
    display: flex;
    align-items: end;
    justify-content: end;
    padding-right: 20px;
    font-weight: bold;
  }

  @media(max-width: 564px){
    .table-section{
      overflow-x: scroll;
    }
    .btn-left{
      padding-right: 0;
      font-weight: lighter;
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/statuspage/status.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;IACE;MACE,kBAAkB;IACpB;IACA;MACE,gBAAgB;MAChB,oBAAoB;IACtB;EACF","sourcesContent":["\n.details-table { \n    margin-top: 20px;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 10px;\n    padding: 10px;\n  }\n  \n  thead {\n    background-color: #f2f2f2;\n  }\n  \n  th {\n    font-weight: bold;\n  }\n  .btn-left{\n    display: flex;\n    align-items: end;\n    justify-content: end;\n    padding-right: 20px;\n    font-weight: bold;\n  }\n\n  @media(max-width: 564px){\n    .table-section{\n      overflow-x: scroll;\n    }\n    .btn-left{\n      padding-right: 0;\n      font-weight: lighter;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
