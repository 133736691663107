import Mouse from "./../svgs/computer-mouse-solid.svg";
import PC from "./../svgs/computer-solid.svg";
import Laptop from "./../svgs/laptop-solid.svg";
import Monitor from "./../svgs/desktop-solid.svg";
import Headset from "./../svgs/headset-solid.svg";
import Camera from "./../svgs/camera-solid.svg";
import Keyboard from "./../svgs/keyboard-solid.svg";
import Speaker from "./../svgs/speakers-svgrepo-com.svg";

// import { MdSpeaker } from "react-icons/md";
import {faComputerMouse,faComputer,faLaptop,faDesktop,faHeadset,faCamera,faKeyboard,faServer} from '@fortawesome/free-solid-svg-icons';
const MdSpeaker = 'MdSpeaker';
const icons =[   
    {
        type:"PC/Desktop",
        icon: faComputer,
        category: 'Primary'
    },
    {
        type:"Laptop",
        icon: faLaptop,
        category: 'Primary'
    },
    {
        type:"Mouse",
        icon: faComputerMouse,
        category: 'Secondary' 
    },
    {
        type:"Monitor",
        icon: faDesktop,
        category: 'Secondary' 
    },
    {
        type:"Headset",
        icon: faHeadset,
        category: 'Secondary' 
    },
    {
        type:"Camera",
        icon: faCamera,
        category: 'Secondary' 
    },
    {
        type:"Keyboard",
        icon: faKeyboard,
        category: 'Secondary' 
    },
    {
        type:"Speaker",
        icon: MdSpeaker,
        category: 'Secondary' 
    },
    {
        type:"Servers",
        icon: faServer,
        category: 'Secondary' 
    },
]

export default icons;