import React from 'react';
import '../../styles/popupq/popupq.css'; // Import the CSS file for styling

const Popupq = ({ message, data1, data2, setShowPopup, resolveFunction }) => {
    const handleButtonClick = (value) => {
        // Call the resolve function with the appropriate value when a button is clicked
        resolveFunction(value);
        // Close the popup
        setShowPopup(false);
    };

    return (
        <div className='outer-container'>
            <div className="popupq">
                <p className="popupq_text">{message}</p>
                <button className="popupq_button1" onClick={() => handleButtonClick(true)}>{data1}</button>
                <button className="popupq_button2" onClick={() => handleButtonClick(false)}>{data2}</button>
            </div>
        </div>
    );
};

export default Popupq;
